<template>
	<v-container>
		<v-row class="align-center">
			<v-col cols="8" md="9">
				<h2>Модулі</h2>
			</v-col>
			<v-col cols="4" md="3" class="text-right">
				<v-btn
					bottom
					right
					small
					text
					color="secondary"
					class="text-none v-btn--filled"
					@click="$refs.table.addItem()">
					<v-icon>mdi-plus</v-icon>
					<span class="d-none d-sm-inline">Додати модуль</span>
				</v-btn>
			</v-col>
		</v-row>

		<a-table
			ref="table"
			:items="modules"
			:headers="headers"
			:schema="schema"
			:uiSchema="uiSchema"
			@create="createModule"
			@update="updateModule"
			@delete="deleteModule">

			<template #item.name="{ item }">
				<router-link
					:to="{
						name: 'module',
						params: {
							moduleid: item.id,
						},
					}">
					{{ item.name }}
				</router-link>
			</template>
		</a-table>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { apiFactory } from '../api/apiFactory'

const modulesApi = apiFactory.get('modules')
const coursesApi = apiFactory.get('courses')

export default {
	data: () => ({
		modules: [],
		courses: [],
		headers: [
			{
				text: 'Номер',
				value: 'num',
			},
			{
				text: 'Модуль',
				value: 'name',
			},
			{
				text: 'Курс',
				value: 'course_id',
			},
			{
				text: 'Дії',
				value: 'actions',
			},
		],
		schema: {
			type: 'object',
			properties: {
				num: {
					type: 'string',
				},
				name: {
					type: 'string',
				},
				// eslint-disable-next-line camelcase
				course_id: {
					type: 'number',
				},
			},
		},
	}),
	computed: {
		uiSchema() {
			return [
				{
					component: 'v-text-field',
					model: 'num',
					fieldOptions: {
						class: ['flex xs12 sm2'],
						on: ['input'],
						attrs: {
							label: 'Номер',
							type: 'number',
						},
					},
				},
				{
					component: 'v-text-field',
					model: 'name',
					fieldOptions: {
						class: ['flex xs12 sm10'],
						on: ['input'],
						attrs: {
							label: 'Назва',
						},
					},
				},
				{
					component: 'v-select',
					model: 'course_id',
					fieldOptions: {
						class: ['flex xs12'],
						on: ['input'],
						attrs: {
							label: 'Курс',
							items: this.courses,
							'item-value': 'id',
							'item-text': 'name',
						},
					},
				},
			]
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),
		async loadModules() {
			try {
				const { data } = await modulesApi.getAll()
				this.modules = data
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список модулів',
					color: 'error',
				}, { root: true })
			}
		},
		async createModule(item) {
			try {
				const { data } = await modulesApi.create(item)
				this.modules.unshift(data)
				this.notify({
					text: 'Модуль додано',
					color: 'success',
				}, { root: true })
			} catch (error) {
				this.notify({
					text: 'Не вдалося оновити модуль',
					color: 'error',
				}, { root: true })
			}
		},
		async updateModule(item, index) {
			try {
				const { data } = await modulesApi.update(item)
				this.$set(this.modules, index, data)
				this.notify({
					text: 'Модуль оновлено',
					color: 'success',
				}, { root: true })
			} catch (error) {
				this.notify({
					text: 'Не вдалося оновити модуль',
					color: 'error',
				}, { root: true })
			}
		},
		async deleteModule(item, index) {
			try {
				await modulesApi.delete(item)
				this.$delete(this.modules, index)
				this.notify({
					text: 'Модуль видалено',
					color: 'error',
				}, { root: true })
			} catch (error) {
				this.notify({
					text: 'Не вдалося оновити модуль',
					color: 'error',
				}, { root: true })
			}
		},
		async loadCourses() {
			try {
				const { data } = await coursesApi.getAll()
				this.courses = data
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список курсів',
					color: 'error',
				}, { root: true })
			}
		},
	},
	mounted() {
		this.loadModules()
		this.loadCourses()
	},
}
</script>